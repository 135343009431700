<template>
  <v-form
    ref="form"
    @submit.prevent="changeEmail"
  >
    <v-alert
      v-for="error in errorMessages"
      :key="error"
      type="error"
    >
      {{ error }}
    </v-alert>
    <v-card :loading="submitting">
      <v-card-title>
        Change Email
      </v-card-title>
      <v-card-text v-if="!success">
        <p>Your current email address is {{ profile.email }}. You may enter a new address below.</p>
        <v-text-field
          v-model="email"
          :disabled="submitting"
          :rules="emailRules"
          required
          type="email"
          label="Email"
        />
      </v-card-text>
      <v-card-actions v-if="!success">
        <v-btn
          color="primary"
          :disabled="submitting"
          type="submit"
        >
          Submit
        </v-btn>
      </v-card-actions>
      <v-card-text v-else>
        <p>{{ successMessage }}</p>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import { allianceAPI } from '@/api'
import { emailRules } from '@/utils/rules'
import { mapState } from 'vuex'
import { parseResponseErrors } from '@/utils/errors'

export default {
  data() {
    return {
      email: null,
      submitting: false,
      success: false,
      successMessage: null,
      errorMessages: null,
      emailRules: emailRules,
    }
  },
  computed: {
    ...mapState({
      profile: state => state.auth.profile,
    }),
  },
  methods: {
    changeEmail: function () {
      if (!this.$refs.form.validate()) {
        return
      }
      this.submitting = true
      this.success = false
      this.errorMessages = null
      this.successMessage = 'Your new email address has been submitted.'
      const data = {
        email: this.email,
      }
      return allianceAPI.post('/alliance/email/change/', data)
        .then((response) => {
          this.success = true
          if (response && response.data && response.data.message) {
            this.successMessage = response.data.message
          }
        })
        .catch((error) => {
          this.errorMessages = parseResponseErrors(error)
          this.success = false
        })
        .finally(() => this.submitting = false)
    }
  },
}
</script>
